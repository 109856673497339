import React from "react";
import SearchResultPage from "../../../templates/search-results-map-template";
// import {
//     parseSearchUrl,
//     savedSearchParams,
//     propertyH1
// } from "../../../Components/common/site/utils";

const SearchResultSalesMap = (props) => {

    const fullpath = props.location.pathname
    const location = props.location
    const fullpathone = props.location
    // const searchParams = parseSearchUrl("sales", fullpath);

    return (
        <React.Fragment>
            <SearchResultPage 
                locationname="/new-homes-map/for-sale/" 
                pcategorytype="new_developments" 
                ptype="sales" 
                ptypetag="for-sale" 
                pstatustype= {['For Sale','Exchanged','Under Offer']} 
                fullpathone={fullpathone} 
                fullpathname={fullpath}
                {...props}
            />
        </React.Fragment>
    );
};

export default SearchResultSalesMap;
